import React from 'react'
import './index.css'
import logo from '../../assets/images/logo_white.png'
import footerLogo from '../../assets/images/footer-logo.png'
import linkedInLogo from '../../assets/images/linkedInWhite.png'
import partner from '../../assets/images/footer_partner_png.png'
import { FaLinkedin } from 'react-icons/fa6'

const Footer = () => {
  return (
    <div style={{padding:' 0 0 1rem 0', backgroundColor:'#222'}}>
      <footer className='footer'>
        {/* Left Column */}
        <div className='footer-column left-ft'>
          <img src={logo} alt='Logo' className='footer-logo' />
          <h3 className='footer-heading'>Address</h3>
          <p className='footer-text'>
            My360Tribe
            <br />
            128 Adrian Avenue, <br />
            South San Francisco, CA 94080
          </p>
          {/* <h3 className='footer-heading'>Contact us</h3>
          <a
            href='https://linkedin.com'
            target='_blank'
            rel='noopener noreferrer'
            className='linkedin-link'
          >
            <FaLinkedin  className='linkedin-icon'/>
          </a> */}
        </div>

        {/* Middle Column */}
        <div className='footer-column'>
          <h3 className='footer-heading'>Get Involved</h3>
          <div className='footer-buttons'>
            <button className='footer-button' onClick={() => window.location.href = 'https://dashboard.my360tribe.org/signup'}>Join</button>
            <button className='footer-button' onClick={() => window.location.href = 'https://dashboard.my360tribe.org/signup'}>Volunteer</button>
            {/* <button className='footer-button' onClick={() => window.location.href = 'https://dashboard.my360tribe.org/signup'}>Collaborate</button> */}
            <button className='footer-donate-button' onClick={() => window.location.href = 'https://merchant.reverepayments.dev/forms/35SqPodk7aSXSW6tE3N9-ToxPhV_kGW1sTEVX3iM3X8=/'}>Donate</button>
          </div>
        </div>

        {/* Right Column */}
        <div className='footer-column right-ft'>
          <img
            src={partner}
            alt='Service Partner Logo'
            className='footer-partner-logo'
          />
          <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
          {/* <h3 className='footer-subheading'>Connect With the<br/> Tribe on LinkedIn:</h3> */}
          <h3 className='footer-subheading'>Connect With the <br/>Tribe on LinkedIn</h3>
          <a
            href='https://linkedin.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            {/* <FaLinkedin  className='linkedin-icon'/> */}
            <img src={linkedInLogo} alt='logo' className='linkedin-logo'/>
          </a>
          </div>
          <h3 className='footer-subheading'>Terms and Conditions</h3>
        </div>

        
      </footer>
      <div
        style={{ color: 'white', backgroundColor: '#222', textAlign: 'center', borderTop: '0.5px solid rgba(255, 255, 255, 0.3)', padding:'1.5rem 1rem 0 1rem' }}
      >
        © Copyright {new Date().getFullYear()} All rights reserved. <span style={{color:'#2e5090'}}>My360Tribe</span>. Designed with <span>{" "}</span><img src={footerLogo} alt='logo' style={{height:'12px', width:'15px'}}/> <span style={{color:'#2e5090'}}>SoLoVision</span>
      </div>
      <div
        style={{ color: 'white', backgroundColor: 'black', textAlign: 'center', fontSize:'12px' }}
      >
        {/* Website created by <span>{" "}</span><img src={footerLogo} alt='logo' style={{height:'12px', width:'15px'}}/> Solovision */}
      </div> 
    </div>
  )
}

export default Footer
